import React from 'react';
import { Toolbar } from 'primereact/toolbar';

import { SocialIcon } from 'react-social-icons';

const Footer = () => {
    const leftContents = (
        <div className="block" style={{ textAlign: 'center' }}>
            <div>
                <p><b>About Morton Studios:</b></p>
                <p>App</p>
                <p>Careers</p>
                <p>Blog</p>
            </div>
            <div>
                <p><b>Other Services:</b></p>
                <p>Competitive Play</p>
                <p>Future Technologies</p>
            </div>
        </div>
    );

    const rightContents = (
        <div className="block" style={{ textAlign: 'center' }}>
            <div >
                <p><b>Follow us on:</b></p>
                <SocialIcon network="facebook" />
                <SocialIcon network="twitter" />
                <SocialIcon network="instagram" />
                <SocialIcon network="youtube" />
            </div>
            <div>
                <p><b>Invest in us:</b></p>
                <SocialIcon network="email" />
                {/* Patreon Icon from: https://c5.patreon.com/external/favicon/safari-pinned-tab.svg?v=jw6AR4Rg74 */}
                <SocialIcon url="https://www.patreon.com/" />
            </div>
        </div>
    );

    return (
        <div style={{
            bottom: 0,
            left: 0,
            right: 0,
            width: '100%'
        }}>
            <Toolbar left={leftContents} right={rightContents} />
            <Toolbar style={{ backgroundColor: '#e9ecef' }} left={(
                <React.Fragment>
                    <div style={{ textAlign: 'left' }}>
                        <p>Made in Great Britain © 2020 Morton Studios</p>
                        <p>Privacy Notice</p>
                    </div>
                </React.Fragment>
            )} />
        </div>
    );
}

export default Footer;
