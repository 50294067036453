import React from 'react';

import { Card } from 'primereact/card';
import { ProgressSpinner } from 'primereact/progressspinner';

import { useSelector, useDispatch } from 'react-redux';

import { facebookLogin, googleLogin, appleLogin } from '../reducers/app';
import { FancyButton } from '../components/FancyButton';

const Login = ({ history }) => {

    const dispatch = useDispatch();
    const loading = useSelector(state => state.app.status);

    if (loading === "loading") {
        return (
            <Card style={{ margin: "20px" }} title="Login">
                <ProgressSpinner />
            </Card>
        )
    }

    if(loading === "done") {
        history.push("profile");
    }

    return (
        <Card style={{ margin: "20px" }} title="Login">
            <div style={{ padding: "5px" }}>
                <FancyButton buttonLabel="Login with Facebook" icons="facebook" handleClick={() => dispatch(facebookLogin())} />
            </div>
            <div style={{ padding: "5px" }}>
                <FancyButton buttonLabel="Login with Google" icons="google" handleClick={() => dispatch(googleLogin())} />
            </div>
            <div style={{ padding: "5px" }}>
                <FancyButton buttonLabel="Login with Apple" icons="apple" handleClick={() => dispatch(appleLogin())} />
            </div>
            <div style={{ padding: "5px" }}>
                <FancyButton buttonLabel="WarChest Login" />
            </div>
            <div style={{ padding: "5px" }}>
                <FancyButton buttonLabel="Create an Account" />
            </div>
        </Card>
    )
};

export default Login;
