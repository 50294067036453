import React from 'react';
import ProductCard from '../components/ProductCard';

const Contact = () => (
    <div style={{ verticalAlign: "middle", margin: "0 auto", display: "table" }}>
        <h1><i>Contact Us</i></h1>
        <ProductCard
            title="SEND US A QUESTION"
            subTitle="Ask us anything, from working for us, features, bugs and supporting other table top wargames. We will try and respond back as quickly as possible, but if not feel free to give us a poke!"
            body={`Our Operational Hours:
            
Monday - Friday: 9am - 5pm
Saturday - Sunday: Closed`}
            buttonLabel="Send Message"
        />
    </div>
)

export default Contact;