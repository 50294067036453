import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Menubar } from 'primereact/menubar';

import { withRouter } from 'react-router';

import { logOut } from '../reducers/app';

const Header = ({ history }) => {

    const dispatch = useDispatch();

    const user = useSelector(state => state.app.user);

    const navigateToPage = (path) => history.push(path);

    const items = [
        {
            label: 'Contact Us',
            icon: 'pi pi-fw pi-comments',
            command: () => navigateToPage("/contact")
        },
        {
            label: 'Login',
            icon: 'pi pi-fw pi-users',
            command: () => navigateToPage("/login")
        }
    ]

    if (user) {
        items.pop();
        items.push({ label: 'Tournaments', icon: 'pi pi-fw pi-calendar-plus', command: () => navigateToPage("/tournament") })
        items.push({ label: 'Profile', icon: 'pi pi-fw pi-user', command: () => navigateToPage("/profile") })
        items.push({
            label: 'Logout', icon: 'pi pi-fw pi-power-off', command: () => {
                dispatch(logOut());
                navigateToPage("/");
            }
        });
    }

    return <Menubar model={items} start={<img alt="Warchest logo" className="pi" src="./logo.svg" height="100" onClick={() => navigateToPage("/")} />} />;
}


const ConnectedHeader = withRouter(Header);

export default ConnectedHeader;
