import React from 'react';

import { Route } from 'react-router';
import { useSelector } from 'react-redux';

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './App.css';

import Header from './components/Header';
import Footer from './components/Footer';
import Home from './containers/Home';
import Contact from './containers/Contact';
import Shop from './containers/Login';
import Profile from './containers/Profile';
import Tournament from './containers/Tournament';

const App = () => {
  const error = useSelector(state => state.app.error);

  React.useEffect(() => {
    toast.error(error)
  }, error);

  return (
    <div className="App">
      <ToastContainer />
      <Header />
      <Route exact path="/" component={Home} />
      <Route path="/contact" component={Contact} />
      <Route path="/profile" component={Profile} />
      <Route path="/login" component={Shop} />
      <Route path="/tournament" component={Tournament} />
      <Footer />
    </div>
  )
};

export default App;
