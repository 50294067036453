import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import firebase from "firebase/app";
import PrimeReact from 'primereact/utils';

import createBrowserHistory from 'history/createBrowserHistory';

import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './index.css';

import { firebaseConfig } from './services/firebase.config';

firebase.initializeApp(firebaseConfig);

let History = createBrowserHistory();

PrimeReact.ripple = true;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={History}>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
