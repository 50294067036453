import React from 'react';

import { useSelector } from 'react-redux';

import { ProductCard } from '../components/ProductCard';

import Warhamer from '../assests/warhammer.jpeg';

const Home = ({ history }) => {
    const [headlinerText, setHeadlinerText] = React.useState("Built by Wargamers for Wargamers");

    const user = useSelector(state => state.app.user);

    const navigateToPage = (path) => history.push(path);

    const potentialHeadlinerText = [
        "Built by Wargamers for Wargamers",
        "Using the latest AI Object Detection",
        "Interact with your miniatures in a completely new way",
    ];

    React.useEffect(() => {
        setTimeout(() => {
            const text = potentialHeadlinerText[Math.floor(Math.random() * potentialHeadlinerText.length)];
            setHeadlinerText(text);
        }, 5000);
    })

    return (
        <div style={{
            position: 'relative'
        }}>
            <div style={{
                position: 'relative',
                textAlign: 'center',
                color: 'white',
            }}>
                <img src={Warhamer} alt="Warhammer Army" style={{ width: "100%", height: '100%' }} />
                <h1 style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                }}>
                    {headlinerText}
                </h1>
            </div>
            <div className="p-grid" style={{ paddingTop: "75px" }}>
                <div className="p-col">
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <ProductCard title='Android' icons='android' subTitle="Augmented Reality App" body={`Practise with you army against the computer and play remotely, or in person with our new Augumented Reality app.

Notice - this is a Pre-Alpha version.

Requires ARCore and Developer Tools to install`} handlePress={() => window.location.assign("https://play.google.com/store")} />
                    </div>
                </div>
                <div className="p-col">
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <ProductCard title='iOS' icons='apple' subTitle="Augmented Reality App" body={`Practise with you army against the computer and play remotely, or in person with our new Augumented Reality app.
                        
Notice - this is a Pre-Alpha version. Requires ARCore for certain features.
                        
Coming soon!`} handlePress={() => window.location.assign("https://www.apple.com/uk/ios/app-store/")} />
                    </div>
                </div>
                <div className="p-col">
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <ProductCard title='Global' icons='globe' subTitle="Tournament Creator" body="Shedule, host, virtual and in person tournaments" buttonLabel="Join today" handlePress={() => user ? navigateToPage("tournament") : navigateToPage("login")} />
                    </div>
                </div>
                <div className="p-col">
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <ProductCard title='Global' icons='globe' subTitle="Competitive Ranking" body="Keep a record of all of your match and tournament history" buttonLabel="Join today" handlePress={() => user ? navigateToPage("profile") : navigateToPage("login")} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
