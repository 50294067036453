import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import firebase from "firebase/app";
import "firebase/auth";

const initialState = {
    user: null,
    accessTokens: null,
    status: 'idle',
    error: null
}

export const facebookLogin = createAsyncThunk('app/doFacebookLogin', async () => {
    return await firebase.auth().signInWithPopup(new firebase.auth.FacebookAuthProvider());
});

export const googleLogin = createAsyncThunk('app/doGoogleLogin', async () => {
    return await firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider());
});

export const appleLogin = createAsyncThunk('app/doAppleLogin', async () => {
    return await firebase.auth().signInWithPopup(new firebase.auth.OAuthProvider('apple.com'));
});

const appSlices = createSlice({
    name: 'app',
    initialState,
    reducers: {
        logIn: (state, action) => {
            state.user = action.payload.user;
            state.accessTokens = action.payload.accessToken;
        },
        logOut: state => {
            state.user = null;
            state.accessTokens = null;
            state.status = 'idle';
            state.error = null;
        }
    },
    extraReducers: builder => {
        // Builder for async state handling
        // Facebook Pending
        builder.addCase(facebookLogin.pending, (state, action) => {
            state.status = 'loading';
        })
        // Google Pending
        .addCase(googleLogin.pending, (state, action) => {
            state.status = 'loading';
        })
        // Apple Pending
        .addCase(appleLogin.pending, (state, action) => {
            state.status = 'loading';
        })
        // Facebook Success
        .addCase(facebookLogin.fulfilled, (state, action) => {
            state.user = action.payload.user;
            state.accessTokens = action.payload.credential.accessToken;
            state.status = 'done';
        })
        // Goole Success
        .addCase(googleLogin.fulfilled, (state, action) => {
            state.user = action.payload.user;
            state.accessTokens = action.payload.credential.accessToken;
            state.status = 'done';
        })
        // Apple Success
        .addCase(appleLogin.fulfilled, (state, action) => {
            state.user = action.payload.user;
            state.accessTokens = action.payload.credential.accessToken;
            state.status = 'done';
        })
        // Facebook Error
        .addCase(facebookLogin.rejected, (state, action) => {
            state.error = "An unknown sign in error occured";
            state.status = 'idle';
        })
        // Google Error
        .addCase(googleLogin.rejected, (state, action) => {
            state.error = "An unknown sign in error occured";
            state.status = 'idle';
        })
        // Apple Error
        .addCase(appleLogin.rejected, (state, action) => {
            state.error = "An unknown sign in error occured";
            state.status = 'idle';
        })
    }
});

export const {
    logIn,
    logOut
} = appSlices.actions;

export default appSlices.reducer;