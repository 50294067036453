// DO NOT UPLOAD TO GIT

export const firebaseConfig = {
    apiKey: "AIzaSyB7i_lLxKb9rD102WFR5bLNxesWuB1CZNI",
    authDomain: "warchest-300619.firebaseapp.com",
    projectId: "warchest-300619",
    storageBucket: "warchest-300619.appspot.com",
    messagingSenderId: "763473180112",
    appId: "1:763473180112:web:55c1cca615b87960c7b5d5",
    measurementId: "G-8958MD9Q2K"
};