import React from 'react';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Card } from 'primereact/card';

import { InputNumber } from 'primereact/inputnumber';
import { TabView, TabPanel } from 'primereact/tabview';


import FullCalendar from '../components/PreBuiltCalendar';

import 'primeflex/primeflex.css';

const Tournament = () => {

    const [user, setUser] = React.useState();
    const [value1, setValue1] = React.useState();
    const [date2, setDate2] = React.useState();
    const [events, setEvents] = React.useState([]);
    const [activeIndex, setActiveIndex] = React.useState(0);

    return (
        <div style={{ padding: "20px" }} className="p-text-left">
            <Card title="Tournaments">
                <TabView
                    activeIndex={activeIndex}
                    onTabChange={(e) => setActiveIndex(e.index)}
                >
                    <TabPanel header="All Public Tournaments">
                        <FullCalendar events={events} />
                    </TabPanel>
                    <TabPanel header="Create a Tournament">
                        {
                            user ?
                                <div className="p-fluid p-grid p-formgrid">
                                    <div className="p-field p-col-12 p-md-3">
                                        <label>Attendees</label>
                                        <InputNumber id="integeronly" value={value1} onValueChange={(e) => setValue1(e.value)} />
                                    </div>
                                    <div className="p-field p-col-12 p-md-3">
                                        <label>Set a start date</label>
                                        <Calendar value={date2} onChange={(e) => setDate2(e.value)} showIcon />
                                    </div>
                                    <div className="p-field p-col-12 p-md-3">
                                        <label>Set an end date</label>
                                        <Calendar value={date2} onChange={(e) => setDate2(e.value)} showIcon />
                                    </div>
                                    <div className="p-field p-col-12 p-md-3">
                                        <label>Finished</label>
                                        <Button label="Submit" icon="pi pi-check" iconPos="right" />
                                    </div>
                                </div>
                                :
                                <p>Please sign in</p>
                        }

                    </TabPanel>
                </TabView>
            </Card>
        </div>
    )
};

export default Tournament;