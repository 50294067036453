import { configureStore } from '@reduxjs/toolkit';

import appSlices from '../reducers/app';

const store = configureStore({
    reducer: {
        app: appSlices
    }
});

export default store;
