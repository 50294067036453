import React from 'react';

import { useSelector } from 'react-redux';

import { Card } from 'primereact/card';
import { Chart } from 'primereact/chart';
import { TabView, TabPanel } from 'primereact/tabview';

import FullCalendar from '../components/PreBuiltCalendar';

const Profile = () => {

    const user = useSelector(state => state.app.user);

    const [events, setEvents] = React.useState([]);
    const [activeIndex, setActiveIndex] = React.useState(0);

    const chartData = {
        labels: ['Wins', 'Loses', 'Draws'],
        datasets: [
            {
                data: [300, 50, 100],
                backgroundColor: [
                    "#42A5F5",
                    "#66BB6A",
                    "#FFA726"
                ],
                hoverBackgroundColor: [
                    "#64B5F6",
                    "#81C784",
                    "#FFB74D"
                ]
            }
        ]
    };

    const lightOptions = {
        legend: {
            labels: {
                fontColor: '#495057'
            }
        }
    };

    // TODO: Add in pull functionality via redux
    // React.useEffect(() => {

    //     setEvents(data);
    // }, []);

    return (
        <div style={{ padding: "20px" }} className="p-text-left">
            <Card title={`${user.name}`} subTitle={`Gobal rank: ${user.rank}`}>
                <TabView
                    activeIndex={activeIndex}
                    onTabChange={(e) => setActiveIndex(e.index)}
                >
                    <TabPanel header="Match History">
                        Content I
                </TabPanel>
                    <TabPanel header="Events Calendar">
                        <FullCalendar events={events} />
                    </TabPanel>
                    <TabPanel header="Career Statistics">
                        <Chart type="pie" data={chartData} options={lightOptions} />
                    </TabPanel>
                </TabView>
            </Card>
        </div>
    )
};

export default Profile;