import React from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';

export const ProductCard = ({
    title = "",
    subTitle = "",
    body = "",
    icons,
    buttonLabel = "Download",
    handlePress = () => {console.log("Clicked")}
}) => (
    <Card
        title={title}
        subTitle={subTitle}
        footer={(
            <span><Button label={buttonLabel} icon={`pi pi-${icons}`} onClick={handlePress} /></span>
        )}
        style={{ width: '25rem', marginBottom: '1em', }}
    >
        <p style={{ whiteSpace: "pre-wrap" }}>{body}</p>
    </Card>
);

export default ProductCard;
