import React from 'react';
import { FullCalendar } from 'primereact/fullcalendar';

import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

const options = {
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
    defaultView: 'dayGridMonth',
    defaultDate: '2017-02-01',
    header: {
        left: 'prev,next',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay'
    },
    editable: true
};

const Calendar = ({ events }) => (
    <FullCalendar events={events} options={options} />
);

export default Calendar;